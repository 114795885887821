import React, { useState } from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import CommonModal from '../Utlility/Modal';

const Download = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dialogHeader, setDialogHeader] = useState('');

  const openDialog = (header) => {
    setDialogHeader(header);
    onOpen();
  };

  return (
    <div className="bg-[#75CE6D] text-white py-8 text-center">
      <h2 className="text-3xl font-bold mb-4">Download Brochure Now!</h2>
      <button onClick={() => openDialog('Download Brochure')} className="bg-white text-[#75CE6D] py-2 px-6 rounded-full hover:bg-gray-200 transition duration-300">Download</button>
      <CommonModal isOpen={isOpen} onClose={onClose} headerText={dialogHeader} />
    </div>
  );
};

export default Download;
