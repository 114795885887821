import React, { useState } from 'react';
import logo from '../Assest/logo.webp';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleNavLinkClick = () => {
    // Close the mobile menu if it's open
    if (isOpen) {
      setIsOpen(false);
    }
  };

  return (
    <nav className="bg-white shadow-lg sticky top-0 z-9999">
      <div className="max-w-8xl mx-auto px-4">
        <div className="flex justify-between items-center py-5">
          <Link to="/" onClick={handleNavLinkClick}>
            <div className="flex items-center space-x-4">
              <img src={logo} alt="logo" className="h-10" />
            </div>
          </Link>

          {/* Primary and Secondary Navbar items */}
          <div className="flex items-center space-x-14">
            {/* Primary Navbar items */}
            <div className="hidden md:flex items-center space-x-4">
              <ScrollLink to="home" spy={true} smooth={true} duration={500} className="nav-link cursor-pointer" onClick={handleNavLinkClick}>
                Home
              </ScrollLink>
              <ScrollLink to="about" spy={true} smooth={true} duration={500} className="nav-link cursor-pointer" onClick={handleNavLinkClick}>
                About
              </ScrollLink>
              <ScrollLink to="pricing" spy={true} smooth={true} duration={500} className="nav-link cursor-pointer" onClick={handleNavLinkClick}>
                Pricing
              </ScrollLink>
              <ScrollLink to="gallery" spy={true} smooth={true} duration={500} className="nav-link cursor-pointer" onClick={handleNavLinkClick}>
                Gallery
              </ScrollLink>
              <ScrollLink to="amenities" spy={true} smooth={true} duration={500} className="nav-link cursor-pointer" onClick={handleNavLinkClick}>
                Amenities
              </ScrollLink>
              <ScrollLink to="floor-plan" spy={true} smooth={true} duration={500} className="nav-link cursor-pointer" onClick={handleNavLinkClick}>
                Floor Plan
              </ScrollLink>
            </div>

            {/* Secondary Navbar item */}
            <div className="hidden md:flex items-center ml-6">
              <a href="tel:+918178593744" className="py-2 px-3 bg-green-500 text-white rounded-full hover:bg-green-400 hover:text-white">
                +91 81785 93744
              </a>
            </div>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden flex items-center">
            <button onClick={toggleMenu} className="mobile-menu-button">
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isOpen && (
        <div className="md:hidden bg-white shadow-lg rounded-lg p-4 space-y-2">
          <ScrollLink to="home" spy={true} smooth={true} duration={500} onClick={handleNavLinkClick} className="block py-2 px-4 text-gray-800 rounded-lg hover:bg-gray-100">
            Home
          </ScrollLink>
          <ScrollLink to="about" spy={true} smooth={true} duration={500} onClick={handleNavLinkClick} className="block py-2 px-4 text-gray-800 rounded-lg hover:bg-gray-100">
            About
          </ScrollLink>
          <ScrollLink to="pricing" spy={true} smooth={true} duration={500} onClick={handleNavLinkClick} className="block py-2 px-4 text-gray-800 rounded-lg hover:bg-gray-100">
            Pricing
          </ScrollLink>
          <ScrollLink to="gallery" spy={true} smooth={true} duration={500} onClick={handleNavLinkClick} className="block py-2 px-4 text-gray-800 rounded-lg hover:bg-gray-100">
            Gallery
          </ScrollLink>
          <ScrollLink to="amenities" spy={true} smooth={true} duration={500} onClick={handleNavLinkClick} className="block py-2 px-4 text-gray-800 rounded-lg hover:bg-gray-100">
            Amenities
          </ScrollLink>
          <ScrollLink to="floor-plan" spy={true} smooth={true} duration={500} onClick={handleNavLinkClick} className="block py-2 px-4 text-gray-800 rounded-lg hover:bg-gray-100">
            Floor Plan
          </ScrollLink>
          <a href="tel:+918178593744" className="block py-2 px-4 text-sm mt-2 bg-green-500 text-white rounded-full hover:bg-green-400 hover:text-white">
            +91 81785 93744
          </a>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
