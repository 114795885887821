import React, { useState } from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import CommonModal from '../Utlility/Modal';
import aboutImage from '../Assest/dining_room.jpg';

const About = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dialogHeader, setDialogHeader] = useState('');

  const openDialog = (header) => {
    setDialogHeader(header);
    onOpen();
  };
  return (
    <section id='about' className="bg-white-100 p-12">
      <h2 className="text-3xl font-bold text-center mb-8">3.5 & 4.5 BHK Luxury Apartments by <span className='text-[#75CE6D]'>Signature Titanium</span></h2>
      <div className="flex flex-col md:flex-row items-center justify-between max-w-6xl mx-auto">
        <div className="md:w-1/2">
          <img src={aboutImage} alt="About" className="w-full rounded-lg shadow-lg" />
        </div>
        <div className="md:w-2/4 mt-8 md:mt-0 md:ml-8 text-left">
          <p className="text-lg leading-relaxed">
            Indulge in a fresh level of opulence at the Signature Global high-rise venture situated in Sector 71, Gurgaon. Crafted to inspire a feeling of magnificence, this endeavor presents an assortment of facilities and spacious accommodations, both indoors and outdoors. Nestled in a strategic location, the project flaunts effortless access to vital thoroughfares including SPR, Sohna Road, Extn. Road, Clover Leaf, NH48, and Dwarka Expressway, alongside internal pathways across Gurgaon, guaranteeing convenient reach to every nook and cranny of the city.
          </p>
          <button onClick={() => openDialog('Enquire Now')} className="mt-5 bg-[#75CE6D] text-white py-2 px-6 rounded-full  transition duration-300">Enquire Now</button>
          <CommonModal isOpen={isOpen} onClose={onClose} headerText={dialogHeader} />
        </div>
      </div>
    </section>
  );
};

export default About;
