import React, { useState, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerBody,
  useDisclosure,
  useMediaQuery,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  VStack,
  useToast,
  WrapItem,
} from '@chakra-ui/react';
import { BsPerson, BsFillTelephoneFill } from 'react-icons/bs';
import { MdOutlineEmail } from 'react-icons/md';
// import emailjs from 'emailjs-com';
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';

const CommonModal = ({ isOpen, onClose, headerText }) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const form = useRef(null);
  const toast = useToast();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const modalEmail = (e) => {
    e.preventDefault();

    const formData = new FormData(form.current);
    formData.append('source', 'Signature');
    const requiredFields = ["user_name", "user_phone", "user_email"];
    const isAnyFieldEmpty = requiredFields.some((field) => !form.current[field].value);

    if (isAnyFieldEmpty) {
      toast({
        title: "Please fill in all the details",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsSubmitting(true);

    emailjs
      .sendForm("service_bnqs3nf", "template_6znyofw", form.current, {
        publicKey: "3q48ooOOxL1LhxMEE",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          setIsSubmitting(false);
          toast({
            title: "Thanks For Your Interest",
            description: "Our Team will Contact you soon",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          onClose();
          navigate("/thank-you");
        },
        (error) => {
          console.log("FAILED...", error.text);
          setIsSubmitting(false);
          alert("Error submitting form");
        }
      );
  };

  const formContent = (
    <form ref={form} onSubmit={modalEmail}>
      <Box borderRadius="20px" mt={isMobile ? 0 : 0}>
          <Box bg="white" borderRadius="lg" p={0}>
            <Box  color="#0B0E3F">
              <VStack spacing={2}>
                <FormControl id="name">
                  <FormLabel>Name</FormLabel>
                  <InputGroup borderColor="#E0E1E7">
                    <InputLeftElement pointerEvents="none">
                      <BsPerson color="gray.800" />
                    </InputLeftElement>
                    <Input type="text" size="md" name="user_name" required />
                  </InputGroup>
                </FormControl>
                <FormControl id="number">
                  <FormLabel>Mobile No.</FormLabel>
                  <InputGroup borderColor="#E0E1E7">
                    <InputLeftElement pointerEvents="none">
                      <BsFillTelephoneFill color="gray.800" />
                    </InputLeftElement>
                    <Input type="number" size="md" name="user_phone" required />
                  </InputGroup>
                </FormControl>
                <FormControl id="email">
                  <FormLabel>Email</FormLabel>
                  <InputGroup borderColor="#E0E1E7">
                    <InputLeftElement pointerEvents="none">
                      <MdOutlineEmail color="gray.800" />
                    </InputLeftElement>
                    <Input type="email" size="md" name="user_email" required />
                    <input type="hidden" name="source" value="Signature" />
                  </InputGroup>
                </FormControl>
                <Button variant="solid" bg="#75CE6D" color="white" type="submit" isLoading={isSubmitting}>
                  Submit
                </Button>
              </VStack>
            </Box>
          </Box>
      </Box>
    </form>
  );

  return (
    <>
      {!isMobile ? (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{headerText}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>{formContent}</ModalBody>
          </ModalContent>
        </Modal>
      ) : (
        <Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader>{headerText}</DrawerHeader>
            <DrawerCloseButton />
            <DrawerBody>{formContent}</DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
};

export default CommonModal;
