import React from 'react';

const Details = () => {
  return (
    <div className="bg-[#75CE6D] text-white p-8 flex flex-wrap justify-between space-y-4 md:space-y-0">
      <div className="space-y-2 flex-1 min-w-[200px] bg-white text-black p-4 rounded-lg shadow-md mt-4 md:mt-0 mx-2">
        <h2 className="text-xl font-bold">Highlight</h2>
        <p className="text-lg">7 Swimming Pool</p>
      </div>
      
      <div className="space-y-2 flex-1 min-w-[200px] bg-white text-black p-4 rounded-lg shadow-md mt-4 md:mt-0 mx-2">
        <h2 className="text-xl font-bold">Club House</h2>
        <p className="text-lg">1 lakh sq.ft</p>
      </div>
      
      <div className="space-y-2 flex-1 min-w-[200px] bg-white text-black p-4 rounded-lg shadow-md mt-4 md:mt-0 mx-2">
        <h2 className="text-xl font-bold">Total Tower</h2>
        <p className="text-lg">7</p>
      </div>
      
      <div className="space-y-2 flex-1 min-w-[200px] bg-white text-black p-4 rounded-lg shadow-md mt-4 md:mt-0 mx-2">
        <h2 className="text-xl font-bold">Total Units</h2>
        <p className="text-lg">1100 units</p>
      </div>
      
      <div className="space-y-2 flex-1 min-w-[200px] bg-white text-black p-4 rounded-lg shadow-md mt-4 md:mt-0 mx-2">
        <h2 className="text-xl font-bold">Total Land Parcel</h2>
        <p className="text-lg">125 acre</p>
      </div>
      
      <div className="space-y-2 flex-1 min-w-[200px] bg-white text-black p-4 rounded-lg shadow-md mt-4 md:mt-0 mx-2">
        <h2 className="text-xl font-bold">Sky Terrace</h2>
        <p className="text-lg">30th Floor</p>
      </div>
    </div>
  );
};

export default Details;
