import React from 'react'
import Navbar from './Navbar'
import Header from './Header'
import Details from './Details'
import About from './About'
import Price from './Price'
import Download from './Download'
import Gallery from "./Gallery";
import Amenities from './Amenities'
import FloorPlan from './FloorPlans'
import Footer from './Footer'
import FooterContent from './FooterContent'
import CallSticky from '../Utlility/CallSticky'
import WhatsappSticky from '../Utlility/WhatsappSticky'

const Home = () => {
  return (
    <div>
      <CallSticky />
      <WhatsappSticky />
      <Navbar />
      <Header />
      <Details />
      <About />
      <Price />
      <Download />
      <Gallery />
      <Amenities />
      <FloorPlan />
      <Footer />
      <FooterContent />
    </div>
  )
}

export default Home