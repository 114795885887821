import React from 'react'
import "./WhatsappSticky.css";
import whatsapp from "../Assest/Utility/whatsapp.png"
import { Image, Tooltip, useMediaQuery } from '@chakra-ui/react';
const WhatsappStickty = () => {
  const [isMobile] = useMediaQuery('(max-width: 576px)');
  const openWhatsApp = () => {
    // Replace 'your-phone-number' with your actual WhatsApp number
    window.open('https://api.whatsapp.com/send?phone=+918178593744&text=Hi&utm_source=website+banner', '_blank');
  };
  return (
    <div>
      {isMobile?<></>:
      <>
      <div className="whatsapp-button" style={{ right: isMobile ? "20px" : "60px", top: isMobile ? "75%" : "85%", width: isMobile ? "50px" : "60px", height: isMobile ? "50px" : "60px" }} onClick={openWhatsApp}>
        <Tooltip label='Whatsapp Now' placement='left'><Image src={whatsapp} alt="WhatsApp" /></Tooltip>
      </div>
      </>}
      
    </div>
  )
}

export default WhatsappStickty