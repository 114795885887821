import emailjs from '@emailjs/browser';
import React, { useRef, useState } from 'react';
import { useMediaQuery, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import backgroundImage from '../Assest/entrance.jpg';
import companyLogo from '../Assest/logo.webp';

const Footer = () => {
  const form = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const navigate = useNavigate();
  const sendEmail = (e) => {
    e.preventDefault();

    const formData = new FormData(form.current);
    formData.append('source', 'Signature');
    const requiredFields = ["user_name", "user_phone", "user_email"];
    const isAnyFieldEmpty = requiredFields.some((field) => !form.current[field].value);

    if (isAnyFieldEmpty) {
      toast({
        title: "Please fill in all the details",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsSubmitting(true);

    emailjs
      .sendForm("service_bnqs3nf", "template_6znyofw", form.current, {
        publicKey: "3q48ooOOxL1LhxMEE",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          setIsSubmitting(false);
          toast({
            title: "Thanks For Your Interest",
            description: "Our Team will Contact you soon",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          navigate("/thank-you");
        },
        (error) => {
          console.log("FAILED...", error.text);
          setIsSubmitting(false);
          alert("Error submitting form");
        }
      );
  };
  return (
    <footer
      className="relative bg-cover bg-center h-96 flex items-center"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="absolute inset-0 bg-black opacity-10"></div>
      <div className="relative z-10 max-w-6xl mx-auto px-4 flex flex-col md:flex-row items-center justify-between w-full h-full">
        <div className="mr-4">
          <img src={companyLogo} alt="Company Logo" className="h-24" />
        </div>
        <div className="bg-white bg-opacity-60 p-6 rounded-lg shadow-lg md:w-1/3 mt-8 md:mt-0 flex items-center justify-center">
          <div>
            <h2 className="text-2xl font-bold mb-4">Get Connect With Us</h2>
            <form className="space-y-4" ref={form} onSubmit={sendEmail}>
            <input
              type="text"
              placeholder="Name"
              name="user_name"
              className="w-full p-2 border border-gray-300 rounded-lg"
            />
            <input
              type="text"
              placeholder="Phone Number"
              name="user_phone"
              className="w-full p-2 border border-gray-300 rounded-lg"
            />
            <input
              type="email"
              placeholder="Email"
              name="user_email"
              className="w-full p-2 border border-gray-300 rounded-lg"
            />
            <input type="hidden" name="source" value="Signature" />
            <button
              type="submit"
              className="w-full bg-[#75CE6D] text-white p-2 rounded-full "
              isLoading={isSubmitting}
            >
              Submit
            </button>
          </form>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
