import React, { useState } from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import CommonModal from '../Utlility/Modal';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import image1 from '../Assest/Gallery/gallery1.jpg'; 
import image2 from '../Assest/Gallery/gallery21.jpg'; 
import image3 from '../Assest/Gallery/gallery3.jpg'; 
import image4 from '../Assest/Gallery/gallery4.jpg'; 
import image5 from '../Assest/Gallery/gallery5.jpg'; 
import image6 from '../Assest/Gallery/gallery6.jpg'; 

const Carousel = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dialogHeader, setDialogHeader] = useState('');

  const openDialog = (header) => {
    setDialogHeader(header);
    onOpen();
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="bg-gray-100 py-8" id="gallery">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-3xl font-bold text-center mb-8">Gallery</h2>
        <Slider {...settings}>
          <div className="px-2">
            <img src={image1} alt="Image 1" />
          </div>
          <div className="px-2">
            <img src={image2} alt="Image 2" />
          </div>
          <div className="px-2">
            <img src={image3} alt="Image 3" />
          </div>
          <div className="px-2">
            <img src={image4} alt="Image 4" />
          </div>
          <div className="px-2">
            <img src={image5} alt="Image 5" />
          </div>
          <div className="px-2">
            <img src={image6} alt="Image 6" />
          </div>
        </Slider>
      </div>
      <button onClick={() => openDialog('Get Details')} className="mt-12 bg-[#75CE6D] text-white py-2 px-6 rounded-full hover:bg-green-600 transition duration-300">Get Details</button>
      <CommonModal isOpen={isOpen} onClose={onClose} headerText={dialogHeader} />
    </div>
  );
};

export default Carousel;
