import React, { useState } from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import CommonModal from '../Utlility/Modal';
import floorPlanImageNormal from '../Assest/3.5bhk.webp';
import floorPlanImageBlur from '../Assest/4.5bhk.webp';

const FloorPlan = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dialogHeader, setDialogHeader] = useState('');

  const openDialog = (header) => {
    setDialogHeader(header);
    onOpen();
  };
  return (
    <div className="max-w-6xl mx-auto py-8" id="floor-plan">
      <h2 className="text-3xl font-bold text-center mb-8">Floor Plan</h2>
      <div className="flex flex-col items-center justify-center space-y-8 relative md:flex-row md:justify-center md:space-y-0">
        {/* Normal Image */}
        <div className="relative flex justify-center">
          <img
            src={floorPlanImageNormal}
            alt="Floor Plan Normal"
            className="w-full md:w-100 h-auto transition duration-300 ease-in-out filter brightness-75 blur-sm"
          />
          {/* Button */}
          <button onClick={() => openDialog('Floor Plan Details')} className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#75CE6D] text-white px-6 py-3 rounded-full shadow-lg transition duration-300 ease-in-out opacity-100">
            View Details
          </button>
          <CommonModal isOpen={isOpen} onClose={onClose} headerText={dialogHeader} />
        </div>
        {/* Blur Image */}
        <div className="relative flex justify-center">
          <img
            src={floorPlanImageBlur}
            alt="Floor Plan Blur"
            className="w-full md:w-100 h-auto transition duration-300 ease-in-out filter brightness-75 blur-sm"
          />
          {/* Button */}
          <button onClick={() => openDialog('Floor Plan Details')} className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#75CE6D] text-white px-6 py-3 rounded-full shadow-lg transition duration-300 ease-in-out opacity-100">
            View Details
          </button>
        </div>
      </div>
    </div>
  );
};

export default FloorPlan;
