import React, { useState } from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import CommonModal from '../Utlility/Modal';

const Price = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dialogHeader, setDialogHeader] = useState('');

  const openDialog = (header) => {
    setDialogHeader(header);
    onOpen();
  };
  return (
    <div className="bg-gray-100 py-12" id="pricing">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-3xl font-bold text-center mb-8">Price</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white rounded-lg shadow-xl p-8 text-center">
            <h2 className="text-xl  mb-4">Type A</h2>
            <p className="text-3xl font-semibold mb-4 text-[#75CE6D]">3.5 BHK Homes</p>
            <div className="border-gray-200 py-4 mb-4">
              <p className="text-lg">AREA</p>
              <p className="text-2xl font-semibold text-[#75CE6D]">2600 - 2800 Sq.Ft.</p>
            </div>
            <button onClick={() => openDialog('Type A Details')} className="bg-[#75CE6D] text-white py-2 px-6 rounded-full hover:bg-green-600 transition duration-300">₹4.42 CR*</button>
            <CommonModal isOpen={isOpen} onClose={onClose} headerText={dialogHeader} />
          </div>
          <div className="bg-white rounded-lg shadow-xl p-8 text-center">
            <h2 className="text-xl  mb-4">Type B</h2>
            <p className="text-3xl font-semibold mb-4 text-[#75CE6D]">4.5 BHK Homes</p>
            <div className="border-gray-200 py-4 mb-4">
              <p className="text-lg">AREA</p>
              <p className="text-2xl font-semibold text-[#75CE6D]">3200 Sq.Ft.</p>
            </div>
            <button onClick={() => openDialog('Type B Details')} className="bg-[#75CE6D] text-white py-2 px-6 rounded-full hover:bg-green-600 transition duration-300">ON REQUEST</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Price;
