import { Image, Tooltip, useMediaQuery } from '@chakra-ui/react'
import React from 'react';
import callimage from "../Assest/Utility/callimage.png";
import "./CallSticky.css";
import { Link } from 'react-router-dom';


const CallSticky = () => {
    const [isMobile] = useMediaQuery('(max-width:768px)')
    return (
        <div>
            {isMobile?<></>:
            <>
            <div className="call-button" style={{ right: isMobile ? "20px" : "60px",top:isMobile?"85%": "74%" , width: isMobile ? "50px" : "60px", height: isMobile ? "50px" : "60px" }} >
                <Link to="tel:+918178593744"><Tooltip label='Call Now' placement='left'><Image src={callimage} alt="call" /></Tooltip></Link>
            </div>
            </>}
            {/* right: isMobile ? "20px" : "60px", top: isMobile ? "50%" : "85%" */}
            
        </div>
    )
}

export default CallSticky