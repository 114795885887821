import React from 'react';
import { FaBuilding, FaUniversity, FaChild, FaGamepad, FaStreetView, FaFutbolO, FaMagic, FaCutlery, FaPaw, FaUsers, FaTree, FaSkyatlas } from 'react-icons/fa';

const Features = [
  {
    icon: FaBuilding,
    title: "Community Building"
  },
  {
    icon: FaUniversity,
    title: "Multi Purpose Court"
  },
  {
    icon: FaChild,
    title: "Pool Area"
  },
  {
    icon: FaGamepad,
    title: "Kids Play Area"
  },
  {
    icon: FaStreetView,
    title: "Gymnasium"
  },
  {
    icon: FaMagic,
    title: "Amphitheatre"
  },
  {
    icon: FaPaw,
    title: "Pet Park"
  },
  {
    icon: FaUsers,
    title: "Party Lawn"
  },
  {
    icon: FaTree,
    title: "Linear Park"
  },
  {
    icon: FaSkyatlas,
    title: "Skating Rink"
  }
];

const Amenities = () => {
  return (
    <div className="bg-[#75CE6D] py-8" id="amenities">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-3xl font-bold text-center mb-8">Amenities</h2>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {Features.map((feature, index) => (
            <div key={index} className="flex flex-col items-center text-white">
              <feature.icon className="text-5xl mb-2" />
              <span>{feature.title}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Amenities;
